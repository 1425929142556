import './bootstrap';
import '../css/app.css';
import 'vue-select/dist/vue-select.css';
import '@Assets/css/vue-select.css';
import 'tippy.js/dist/tippy.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import Notifications from 'notiwind';
import VueCreditCardValidation from 'vue-credit-card-validation';
import ClickOutside from '@Core/Composables/clickOutside';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import vSelect from 'vue-select';
// import Polygon from "@Core/Components/Icons/Polygon.vue";
// import CancelIcon from "@Core/Components/Icons/CancelIcon.vue";
import { register } from 'swiper/element/bundle';
import Vue3TouchEvents from 'vue3-touch-events';
import { plugin as VueTippy } from 'vue-tippy';

// Set the components prop default to return our fresh components
// vSelect.props.components.default = () => ({
// 	Deselect: CancelIcon,
// 	OpenIndicator: Polygon,
// });

createInertiaApp({
	progress: {
		color: '#428bca'
	},
	title: (title) => `${title} - Bluebook Of Gun Values`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/Dashboard/**/*.vue')),
	setup({ el, App, props, plugin }) {
		const app = createApp({ render: () => h(App, props) });

		if (import.meta.env.VITE_SENTRY_ENABLED !== 'false') {
			Sentry.init({
				app,
				dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
				release: 'bluebook@v.1.0.0',
				integrations: [new BrowserTracing(), new Sentry.Replay()],
				// Performance Monitoring
				tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
				// Session Replay
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0
			});
		}

		app.use(plugin)
			.use(ZiggyVue, window.ziggy)
			.use(Notifications)
			.use(VueCreditCardValidation)
			.use(Vue3TouchEvents)
			.use(
				VueTippy,
				// optional
				{
					directive: 'tippy', // => v-tippy
					component: 'tippy', // => <tippy/>
					componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
					defaultProps: {
						placement: 'top',
						allowHTML: true,
						zIndex: 99999999999999
					} // => Global default options * see all props
				}
			)
			.component('vSelect', vSelect)
			.directive('click-outside', ClickOutside)
			.provide('$swal', Swal)
			.mount(el);

		return app;
	}
});

register();
